import { useEffect, useState } from "react";

export default function useIsOffline() {
  const [isOffline, setIsOffline] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      if (isOffline && navigator.onLine) {
        setIsOffline(false);
      } else if (!isOffline && !navigator.onLine) {
        setIsOffline(true);
      }
    }, 500);
    return () => clearInterval(interval);
  }, [isOffline]);

  return isOffline;
}
