import { useEffect, useState } from "react";
import { Container, Button, Spinner } from "react-bootstrap";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import useCancelReservation from "../../hooks/useCancelReservation";
import { useToast } from "../../components/Toast";

const COUNTDOWN = 45;

interface LocationProps {
  recipient?: Recipient;
  reservation: Reservation;
}

export default function DepositSuccess() {
  const { t } = useTranslation();
  const history = useHistory<LocationProps>();
  const [counter, setCounter] = useState(COUNTDOWN);
  const [cancelled, setCancelled] = useState(false);
  const { warn } = useToast();

  const cancelReservation = useCancelReservation({
    onSuccess: () => setCancelled(true),
    onError: () => warn(),
  });

  const state = history.location.state;
  const recipient = state?.recipient;
  const reservation = state?.reservation;

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (counter > 0) {
      timeout = setTimeout(() => setCounter(counter - 1), 1000);
    } else {
      history.push("/");
    }
    return () => clearTimeout(timeout);
  }, [counter, history]);

  return (
    <div className="position-absolute h-100 w-100 top-0 left-0">
      <Header title={t("depositSuccess.title")} />

      <Container className="p-3 w-75" style={{ marginTop: 100 }}>
        <div className="border-start border-3 rounded border-primary bg-surface text-on-surface text-center shadow px-5 py-4">
          <div className="display-4 fw-light mb-2">
            {t("depositSuccess.thanks")}{" "}
            <i className="bi bi-patch-check ms-2 text-primary"></i>
          </div>

          <p className="fw-light fs-4">{t("depositSuccess.subtitle")}</p>

          <hr className="my-4" />

          <div className="d-flex justify-content-between">
            {recipient && reservation && reservation?.id ? (
              <>
                <Button
                  disabled={
                    !reservation || cancelReservation.isLoading || cancelled
                  }
                  size="lg"
                  style={{ width: 230 }}
                  variant="secondary"
                  className="rounded-pill shadow-sm bg-gradient"
                  onPointerUp={() => {
                    if (!reservation || !reservation.id) return;
                    cancelReservation.mutate(reservation.id);
                  }}
                >
                  {cancelReservation.isLoading ? (
                    <Spinner className="me-2" animation="border" />
                  ) : cancelled ? (
                    <>
                      <i className="bi bi-check-circle-fill me-2"></i>{" "}
                      {t("depositSuccess.cancelled")}
                    </>
                  ) : (
                    <>
                      <i className="bi bi-x-circle-fill me-2"></i>{" "}
                      {t("depositSuccess.cancel")}
                    </>
                  )}
                </Button>
                <Button
                  size="lg"
                  className="rounded-pill shadow-sm bg-gradient"
                  style={{ width: 180 }}
                  onPointerUp={() => history.push("/")}
                >
                  <i className="bi bi-house-fill me-2"></i>{" "}
                  {t("depositSuccess.home")} (
                  <code className="text-white">{counter}</code>)
                </Button>
                <Button
                  size="lg"
                  className="rounded-pill shadow-sm bg-gradient"
                  onPointerUp={() => history.push("/select-recipient")}
                >
                  <i className="bi bi-plus-circle-fill me-2"></i>{" "}
                  {t("depositSuccess.newDeposit")}
                </Button>
              </>
            ) : (
              <Button
                size="lg"
                className="rounded-pill shadow-sm bg-gradient mx-auto"
                style={{ width: 180 }}
                onPointerUp={() => history.push("/")}
              >
                <i className="bi bi-house-fill me-2"></i>{" "}
                {t("depositSuccess.home")} (
                <code className="text-white">{counter}</code>)
              </Button>
            )}
          </div>
        </div>
      </Container>
      <Footer showBack={false} />
    </div>
  );
}
