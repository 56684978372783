import { PropsWithChildren, ReactNode } from "react";
import { Card } from "react-bootstrap";

export interface DoorProps {
  title: string | ReactNode;
  text?: string;
  disabled?: boolean;
}

export function Door({
  title,
  text,
  children,
  disabled,
}: PropsWithChildren<DoorProps>) {
  return (
    <Card className="mx-4 bg-gradient shadow border-0 border-start border-3 border-primary bg-surface text-on-surface">
      <Card.Body>
        <Card.Title
          className={`text-capitalize text-center ${disabled && "text-muted"}`}
        >
          {title}
        </Card.Title>
        <Card.Text>{text}</Card.Text>
        {children}
      </Card.Body>
    </Card>
  );
}
