import { useTranslation } from "react-i18next";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { Container, Row, Col } from "react-bootstrap";
import { Code } from "./components/Code";
import NewReservation from "./NewReservation";

function Head() {
  const { t } = useTranslation();
  return (
    <Header>
      <h1 className="display-5">Expedilock</h1>
      <p className="lead">{t("home.title")}</p>
      <Container fluid className="mt-3">
        <Row>
          <Col>
            <h1 className="fw-light mb-0">{t("home.code")}</h1>
          </Col>
          <Col>
            <h1 className="fw-light mb-0">{t("home.new")}</h1>
          </Col>
        </Row>
      </Container>
    </Header>
  );
}

function Home() {
  return (
    <div className="position-absolute h-100 w-100 top-0 left-0">
      <Head />

      <Container className="mt-4" fluid>
        <Row>
          <Col>
            <Code />
          </Col>
          <Col className="border-start border-primary">
            <NewReservation />
          </Col>
        </Row>
      </Container>

      <Footer showBack={false} />
    </div>
  );
}

export default Home;
