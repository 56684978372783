import { Modal } from "react-bootstrap";
import { useToast } from "../../components/Toast";
import useGetLocker from "../../hooks/useGetLocker";

interface Props {
  show: boolean;
  onHide: () => any;
  code: string;
}

export function ConfirmAddressHelpModal({ show, onHide, code }: Props) {
  const { warn } = useToast();
  const locker = useGetLocker({ refetchOnMount: false, onError: () => warn() });
  const location = locker.data?.location;

  return (
    <Modal show={show} onHide={onHide} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <i className="bi-question-circle me-2"></i>Aide
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>
          <strong>Comment savoir si je suis au bon casier ?</strong>
        </p>

        <p>
          Retrouvez la notification qui vous a été envoyé et assurez-vous que
          les informations sont exactes:
        </p>

        <p>
          Code: <strong>{code}</strong> <br />
          Adresse:{" "}
          <strong>
            {location?.street1} {location?.street2} {location?.city} (
            {location?.zip_code})
          </strong>
        </p>

        <p>
          Si ces information sont exactes, il se peut que la réservation ait
          déjà été retiré ou qu'elle n'existe plus.
        </p>

        <p>
          Vous pouvez nous contacter en vous dirigeant vers la page d'aide du
          casier.
        </p>
      </Modal.Body>
    </Modal>
  );
}
