import { useTranslation } from "react-i18next";
import useGetLocker from "../../hooks/useGetLocker";
import { useEffect, useRef } from "react";
import { useToast } from "../Toast";

export function useDefaultLanguage() {
  const { i18n } = useTranslation();
  const { warn } = useToast();
  const lockerQuery = useGetLocker({ onError: () => warn() });
  const hasFetchedData = useRef(false);

  useEffect(() => {
    if (
      !hasFetchedData.current &&
      lockerQuery.data &&
      i18n.language !== lockerQuery.data.config.language
    ) {
      i18n.changeLanguage(lockerQuery.data.config.language);
      hasFetchedData.current = true;
    }
  }, [lockerQuery.data, i18n]);
}
