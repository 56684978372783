import { useMemo, createRef } from "react";
import { Container, Row, Col, Button, Spinner } from "react-bootstrap";
import { useQueryClient } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { PageTitle } from "../../components/PageTitle";
import useCreateReservation from "../../hooks/useCreateReservation";
import useGetLocker from "../../hooks/useGetLocker";
import { Door } from "./Door";
import { Placeholders } from "./Placeholders";
import Webcam from "react-webcam";
import useOpenDoor from "../../hooks/useOpenDoor";
import { useToast } from "../../components/Toast";

interface LocationProps {
  recipient?: Recipient;
}

const DOOR_SIZES: { [key: string]: number } = {
  small: 0,
  medium: 10,
  large: 20,
  "x-large": 30,
  room: 40,
};

export default function SelectDoor() {
  const { t } = useTranslation();
  const { warn } = useToast();
  const history = useHistory();
  const queryClient = useQueryClient();
  const lockerQuery = useGetLocker({ onError: () => warn() });
  const webcamRef = createRef<Webcam & HTMLVideoElement>();
  const openDoor = useOpenDoor();

  const {
    state: { recipient },
  } = useLocation<LocationProps>();

  const name = `${recipient?.first_name} ${recipient?.last_name}`;

  const createReservation = useCreateReservation({
    onSuccess: (reservation) =>
      history.push("/deposit-success", { recipient, reservation }),
    onError: () => warn(),
  });

  const doors = useMemo(() => {
    if (!lockerQuery.data) return {};
    return lockerQuery.data.doors.reduce<{ [key: string]: Door[] }>(
      (prev, curr) => {
        if (!(curr.dimension in prev)) prev[curr.dimension] = [];
        if (curr.status === "ready") prev[curr.dimension].push(curr);
        return prev;
      },
      {}
    );
  }, [lockerQuery.data]);

  return (
    <div className="position-absolute h-100 w-100 top-0 left-0">
      <Webcam
        muted={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        style={{ position: "absolute", right: "100%" }}
      />

      <Header title={t("doors.title")} />

      <PageTitle>
        {createReservation.isLoading ? (
          <Spinner className="me-2" animation="border" />
        ) : (
          <i className="bi-box-seam me-2"></i>
        )}
        {t("doors.package")} <strong>{name}</strong>
      </PageTitle>

      <Container className="mt-5">
        {lockerQuery.isLoading && <Placeholders />}

        {recipient && !lockerQuery.isLoading && (
          <Row className="gy-5">
            {Object.keys(doors)
              .sort((a, b) => DOOR_SIZES[a] - DOOR_SIZES[b])
              .map((key) => (
                <Col key={key} md="4">
                  <Door
                    title={`${t(`common.${key}`)} (${doors[key].length})`}
                    disabled={!doors[key].length || createReservation.isLoading}
                  >
                    <div className="d-grid shadow-sm">
                      <Button
                        disabled={
                          !doors[key].length || createReservation.isLoading
                        }
                        size="lg"
                        onPointerUp={() => {
                          createReservation.mutate({
                            first_name: recipient.first_name,
                            last_name: recipient.last_name,
                            email: recipient.email,
                            cellphone_number: recipient.mobile_number,
                            recipient_id: recipient.id,
                            door_position: doors[key][0].position,
                            deposit: true,
                            deposit_photo:
                              webcamRef.current?.getScreenshot() || undefined,
                          });
                          openDoor.mutate(doors[key][0].position);
                          queryClient.invalidateQueries("locker");
                        }}
                        className="rounded-pill"
                      >
                        {doors[key].length ? (
                          <>
                            {t("doors.open")}{" "}
                            <i className="bi bi-arrow-right ms-1"></i>
                          </>
                        ) : (
                          t("doors.unavailable")
                        )}
                      </Button>
                    </div>
                  </Door>
                </Col>
              ))}
          </Row>
        )}
      </Container>

      <Footer />
    </div>
  );
}
