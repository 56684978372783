import { PropsWithChildren } from "react";
import { Container } from "react-bootstrap";

export default function PageTitle({ children }: PropsWithChildren<{}>) {
  return (
    <Container className="my-5 text-center text-on-background">
      <h1 className="fw-light fs-3">{children}</h1>
    </Container>
  );
}
