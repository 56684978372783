import { createRef } from "react";
import { Switch, Route } from "react-router";
import { useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { DepositSuccess } from "../../pages/DepositSuccess";
import { Help } from "../../pages/Help";
import { Home } from "../../pages/Home";
import { InvalidCode } from "../../pages/InvalidCode";
import { RecipientPassword } from "../../pages/RecipientsPassword";
import { SelectDoor } from "../../pages/SelectDoor";
import { SelectRecipient } from "../../pages/SelectRecipient";
import { WithdrawSuccess } from "../../pages/WithdrawSuccess";
import { useDefaultLanguage } from "./useDefaultLanguage";
import { useRedirectHomeOnIdle } from "./useRedirectHomeOnIdle";
import { useRefreshWhenStale } from "./useRefreshWhenStale";

export default function Router() {
  const nodeRef = createRef<any>();
  const location = useLocation();
  useDefaultLanguage();
  useRedirectHomeOnIdle();
  useRefreshWhenStale();
  return (
    <TransitionGroup>
      <CSSTransition
        nodeRef={nodeRef}
        key={location.key}
        classNames="fade"
        timeout={250}
      >
        <div ref={nodeRef}>
          <Switch location={location}>
            <Route exact path="/recipients-password">
              <RecipientPassword />
            </Route>
            <Route exact path="/deposit-success">
              <DepositSuccess />
            </Route>
            <Route exact path="/withdraw-success">
              <WithdrawSuccess />
            </Route>
            <Route exact path="/select-door">
              <SelectDoor />
            </Route>
            <Route exact path="/select-recipient">
              <SelectRecipient />
            </Route>
            <Route exact path="/invalid-code">
              <InvalidCode />
            </Route>
            <Route exact path="/help">
              <Help />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
}
