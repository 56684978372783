import { PropsWithChildren, ReactNode } from "react";
import { Container } from "react-bootstrap";
import { LangSwitcher } from "../LangSwitcher";

interface Props {
  title?: string | ReactNode;
}

function Title({ children }: PropsWithChildren<{}>) {
  return (
    <div className="d-flex justify-content-around">
      <h1 className="fw-light mb-0">{children}</h1>
    </div>
  );
}

export default function Header({ title, children }: PropsWithChildren<Props>) {
  return (
    <Container
      className="py-3 bg-surface-variant shadow text-on-surface-variant text-center"
      style={{ minHeight: 80 }}
      fluid
    >
      <LangSwitcher />
      {title && <Title>{title}</Title>}
      {children}
    </Container>
  );
}
