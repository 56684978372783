import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import background from "./alex-expedibox.jpg";
import logo from "./logo.png";

function Help() {
  const { t } = useTranslation();
  const history = useHistory();
  const [clicks, setClicks] = useState(0);

  useEffect(() => {
    if (clicks >= 3) {
      window.location.reload();
    }
  }, [clicks]);

  return (
    <div className="container-fluid h-100 position-absolute top-0 left-0">
      <div className="row h-100">
        <div className="col-7 p-5 h-100">
          <div className="d-flex mb-5 align-items-center">
            <img
              className=""
              src={logo}
              alt="logo"
              style={{ width: "200px" }}
            />
            <span className="text-muted">{process.env.REACT_APP_VERSION}</span>
          </div>

          <h1 className="text-primary display-5 mb-3">{t("help.title")}</h1>

          <p className="lead">{t("help.p1")}</p>

          <p className="lead">{t("help.p2")}</p>

          <hr className="my-5" />

          <p className="lead mb-4">
            {t("help.p3")} <br />
            {t("help.p4")}
          </p>

          <p>
            <i className="bi bi-phone me-2"></i>
            1-833-622-0304
          </p>

          <p>
            <i className="bi bi-envelope me-2"></i>
            info@expedibox.com
          </p>

          <p>
            <i className="bi bi-globe me-2"></i>
            support.expedibox.com
          </p>

          <button
            className="btn btn-link fs-3 text-decoration-none ps-0 mt-5"
            onPointerUp={() => history.goBack()}
          >
            <i className="bi bi-arrow-left-circle me-2"></i>
            {t("help.back")}
          </button>
        </div>
        <div
          className="h-100 col-5"
          style={{
            backgroundImage: `url(${background})`,
            backgroundSize: "cover",
          }}
          onPointerUp={() => setClicks(clicks + 1)}
        ></div>
      </div>
    </div>
  );
}

export default Help;
