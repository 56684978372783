import { Row, Col, Placeholder } from "react-bootstrap";
import { Door } from "./Door";

export function Placeholders() {
  return (
    <Row className="gy-5">
      {[1, 2, 3].map((key) => (
        <Placeholder key={key} as={Col} animation="glow">
          <Door title={<Placeholder className="w-75" />}>
            <Placeholder.Button
              className="rounded-pill"
              xs={8}
              aria-hidden="true"
            />
          </Door>
        </Placeholder>
      ))}
    </Row>
  );
}
