import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import fr from "../../i18n/fr.json";
import en from "../../i18n/en.json";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    fr: { translation: fr },
  },
  lng: "fr",
  fallbackLng: "fr",
  interpolation: {
    escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
});
