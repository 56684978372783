import { useState, createRef } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router";
import Webcam from "react-webcam";
import { Numpad } from "../../../../components/Numpad";
import { useToast } from "../../../../components/Toast";
import useGetReservations from "../../../../hooks/useGetReservations";
import useOpenDoor from "../../../../hooks/useOpenDoor";
import useUpdateReservation from "../../../../hooks/useUpdateReservation";

const DEPOSIT = "deposit";
const DEPOSIT_PATH = "/deposit-success";
const DEPOSIT_PHOTO = "deposit_photo";
const WITHDRAW = "withdraw";
const WITHDRAW_PHOTO = "withdrawal_photo";
const WITHDRAW_PATH = "/withdraw-success";

export default function Code() {
  const webcamRef = createRef<Webcam & HTMLVideoElement>();
  const [code, setCode] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const history = useHistory();
  const updateReservation = useUpdateReservation();
  const queryClient = useQueryClient();
  const openDoor = useOpenDoor();
  const { warn } = useToast();

  const reservationQuery = useGetReservations(
    { code: code, active: true },
    {
      enabled: !!code,
      onError: () => warn("Une erreur est survenue"),
      onSuccess: (data) => {
        if (data && data.count) {
          const reservation = data.results[0];
          const depositCode = reservation.deposit_code?.toString();
          const action = depositCode === code ? DEPOSIT : WITHDRAW;
          const photo = depositCode === code ? DEPOSIT_PHOTO : WITHDRAW_PHOTO;
          updateReservation.mutate(
            {
              id: reservation.id,
              [action]: true,
              [photo]: webcamRef.current?.getScreenshot(),
            },
            {
              onSuccess: (reservation) => {
                if (reservation.door?.position)
                  openDoor.mutate(reservation.door.position);
                history.push(
                  action === DEPOSIT ? DEPOSIT_PATH : WITHDRAW_PATH,
                  { reservation }
                );
                queryClient.invalidateQueries("locker");
              },
            }
          );
        } else {
          history.push("invalid-code", { code });
        }
      },
    }
  );

  return (
    <>
      {(code || isTyping) && (
        <Webcam
          muted={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          style={{ position: "absolute", right: "100%" }}
        />
      )}

      <Numpad
        className="shadow-sm py-4 rounded bg-surface bg-gradient"
        disabled={reservationQuery.isLoading || updateReservation.isLoading}
        loading={reservationQuery.isLoading || updateReservation.isLoading}
        onSubmit={(code) => setCode(code)}
        onChange={(code) => setIsTyping(!!code)}
      />
    </>
  );
}
