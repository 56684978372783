import { Placeholder, Table } from "react-bootstrap";

function Placeholders() {
  return (
    <tr>
      <Placeholder as="td" animation="glow">
        <Placeholder xs={12} />
      </Placeholder>
      <Placeholder as="td" animation="glow" className="w-50">
        <Placeholder xs={12} />
      </Placeholder>
      <Placeholder as="td" animation="glow">
        <Placeholder xs={12} />
      </Placeholder>
    </tr>
  );
}

function Empty() {
  return (
    <>
      <tr>
        <td className="ps-4">Aucun résultat</td>
      </tr>
      {[1, 2, 3].map((i) => (
        <tr key={i}>
          <td>&nbsp;</td>
        </tr>
      ))}
    </>
  );
}

interface Props {
  recipients: Recipient[];
  loading?: boolean;
  onSelect?: (recipient: Recipient) => any;
  showEmail?: boolean;
}
export default function RecipientList({
  recipients,
  loading,
  onSelect,
  showEmail,
}: Props) {
  return (
    <>
      <Table className="table-surface" striped>
        <tbody>
          {loading && [1, 2, 3, 4].map((i) => <Placeholders key={i} />)}
          {!loading && !recipients.length && <Empty />}

          {!loading &&
            recipients.map((recipient) => (
              <tr
                key={recipient.id}
                onPointerUp={() => {
                  onSelect && onSelect(recipient);
                }}
              >
                <td className="ps-4" style={{ width: 175 }}>
                  {recipient.unit}
                </td>
                <td>
                  {recipient.first_name} {recipient.last_name}
                  <p className="text-muted fs-6 mb-0">
                    {showEmail && recipient.email}
                  </p>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
}
