import { useEffect, useMemo, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router";
import Keyboard from "react-simple-keyboard";
import { useTranslation } from "react-i18next";
import { Footer } from "../../components/Footer";
import { Numpad } from "../../components/Numpad";
import RoundButton from "../../components/RoundButton";
import useGetRecipients from "../../hooks/useGetRecipients";
import RecipientList from "./RecipientList";
import { Header } from "../../components/Header";
import useGetLocker from "../../hooks/useGetLocker";

import "react-simple-keyboard/build/css/index.css";
import { useToast } from "../../components/Toast";

const RECIPIENT_DATA_STALE_TIME = 3 * 3600 * 1000; // 3 hours

const layout = {
  default: [
    "1 2 3 4 5 6 7 8 9 0 {bksp}",
    "Q W E R T Y U I O P -",
    "A S D F G H J K L '",
    "Z X C V B N M , . {shift}",
    "{space}",
  ],
};

export default function SelectRecipient() {
  const { t } = useTranslation();
  const history = useHistory();
  const [filter, setFilter] = useState("");
  const [showNumpad, setShowNumpad] = useState(true);
  const { warn } = useToast();

  const recipientsQuery = useGetRecipients({
    refetchOnMount: true,
    staleTime: RECIPIENT_DATA_STALE_TIME,
    onError: () => warn(),
  });

  const lockerQuery = useGetLocker({
    refetchOnMount: false,
    onError: () => warn(),
  });

  useEffect(() => {
    if (lockerQuery.data && lockerQuery.data.config.default_to_keyboard) {
      setShowNumpad(false);
    }
  }, [lockerQuery.data]);

  const recipients = useMemo(() => {
    if (!recipientsQuery.data) return [];
    return recipientsQuery.data
      .filter((recipient) =>
        `${recipient.unit} ${recipient.first_name} ${recipient.last_name}`
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .toUpperCase()
          .includes(filter)
      )
      .slice(0, 25);
  }, [recipientsQuery.data, filter]);

  return (
    <div className="position-absolute h-100 w-100 top-0 left-0">
      <Header title={t("recipients.title")} />

      <Container className="mt-4 pt-2">
        <Row className="align-items-center gy-3">
          <Col>
            <div className="shadow rounded bg-surface text-on-surface">
              <div
                className="fs-4 fw-light rounded-top"
                style={{
                  minHeight: showNumpad ? 500 : 250,
                  maxHeight: showNumpad ? 500 : 250,
                  overflow: "overlay",
                }}
              >
                <RecipientList
                  recipients={recipients}
                  loading={recipientsQuery.isLoading}
                  showEmail={lockerQuery.data?.config.show_member_emails}
                  onSelect={(recipient: Recipient) =>
                    history.push("/select-door", { recipient })
                  }
                />
              </div>
            </div>
          </Col>

          {!lockerQuery.isLoading && showNumpad && (
            <Col md="5">
              <Numpad
                disabled={recipientsQuery.isLoading}
                className="shadow py-4 rounded bg-surface bg-gradient"
                placeholder={t("recipients.unit")}
                onChange={(code) => setFilter(code)}
                extra={
                  <RoundButton
                    disabled={recipientsQuery.isLoading}
                    onPointerUp={() => {
                      setShowNumpad(false);
                      setFilter("");
                    }}
                  >
                    <i className="bi-keyboard"></i>
                  </RoundButton>
                }
              />
            </Col>
          )}

          {!showNumpad && (
            <Col md="12">
              <div className="p-2 shadow rounded bg-surface bg-gradient">
                <input
                  type="text"
                  className="form-control form-control-lg mb-2"
                  placeholder={t("recipients.filter")}
                  readOnly
                  value={filter}
                />
                <Keyboard
                  layout={layout}
                  onChange={(code: string) => setFilter(code)}
                  display={{ "{shift}": "123" }}
                  mergeDisplay={true}
                  onKeyPress={(button: string) => {
                    if (button === "{shift}") {
                      setShowNumpad(true);
                      setFilter("");
                    }
                  }}
                />
              </div>
            </Col>
          )}
        </Row>
      </Container>
      <Footer showBack={false} showHome />
    </div>
  );
}
