import { Form, Container, Row, Spinner } from "react-bootstrap";
import { useState, ReactNode, useEffect } from "react";
import RoundButton from "../RoundButton/RoundButton";

interface Props {
  disabled?: boolean;
  loading?: boolean;
  placeholder?: string;
  className?: string;
  onSubmit?: (code: string) => any;
  onChange?: (code: string) => any;
  extra?: ReactNode;
  mask?: string;
}

const Numpad: React.FC<Props> = ({
  disabled,
  loading,
  onSubmit,
  onChange,
  placeholder,
  className,
  extra,
  mask,
}) => {
  const CODE_MAX_LENGTH = 12;
  const [code, setCode] = useState("");
  const [codeDisplay, setCodeDisplay] = useState("");

  const updateCode = (code: string) => {
    setCode(code);
    if (onChange) {
      onChange(code);
    }
  };

  useEffect(() => {
    if (mask) {
      setCodeDisplay(code.replace(/./g, mask));
    } else setCodeDisplay(code);
  }, [code, mask]);

  return (
    <Container className={className} style={{ width: 380 }}>
      <Row className="mb-3 px-3">
        <Form.Control
          type="text"
          size="lg"
          className="rounded-pill text-center mx-auto fs-4 shadow-sm"
          readOnly
          value={codeDisplay}
          placeholder={placeholder}
        />
      </Row>
      <Row className="gy-2 gx-0 ms-1">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((number) => (
          <RoundButton
            key={number}
            onPointerUp={() =>
              updateCode((code + number).slice(0, CODE_MAX_LENGTH))
            }
            disabled={disabled}
          >
            {number}
          </RoundButton>
        ))}
        <RoundButton
          onPointerUp={() => updateCode(code.slice(0, -1))}
          disabled={!code.length || disabled}
        >
          <i className="bi-backspace-fill"></i>
        </RoundButton>
        <RoundButton
          onPointerUp={() => updateCode((code + "0").slice(0, CODE_MAX_LENGTH))}
          disabled={disabled}
        >
          0
        </RoundButton>
        {extra}
        {onSubmit && (
          <RoundButton
            variant="info"
            disabled={!code.length || disabled}
            onPointerUp={() => {
              if (onSubmit) onSubmit(code);
              updateCode("");
            }}
          >
            {loading ? <Spinner animation="border" /> : "GO"}
          </RoundButton>
        )}
      </Row>
    </Container>
  );
};

export default Numpad;
