import { useEffect } from "react";

const useIdleTimer = (timeout: number, onIdle: () => any) => {
  useEffect(() => {
    let timerId: number;

    function resetTimer() {
      if (timerId) clearTimeout(timerId);
      timerId = window.setTimeout(onIdle, timeout);
    }

    document.addEventListener("pointerdown", resetTimer);
    resetTimer();

    return () => {
      document.removeEventListener("pointerdown", resetTimer);
      window.clearTimeout(timerId);
    };
  }, [timeout, onIdle]);
};

export default useIdleTimer;
