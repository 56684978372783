import { useMutation, UseMutationOptions } from "react-query";
import { getConfig } from "../config";

async function createReservation(data: Reservation): Promise<Reservation> {
  const { apiUrl, apiKey, lockerId } = getConfig();
  const url = `${apiUrl}/reservations/`;
  const headers = {
    "X-Locker-Private-Key": apiKey,
    "Content-Type": "application/json",
  };
  const response = await fetch(url, {
    headers,
    method: "POST",
    body: JSON.stringify({ ...data, locker_id: lockerId }),
  });
  if (!response.ok) {
    throw new Error("Network Error");
  }
  return response.json();
}

export default function useCreateReservation(options?: UseMutationOptions) {
  return useMutation(
    (data: Reservation) => createReservation(data),
    options as any
  );
}
