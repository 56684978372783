import { Button } from "react-bootstrap";

export default function RoundButton(props: any) {
  return (
    <Button
      style={{ width: 90, height: 90 }}
      className="rounded-circle mx-2 fs-2 shadow"
      {...props}
    />
  );
}
