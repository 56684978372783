import { Container } from "react-bootstrap";
import { Header } from "../../components/Header";

export default function Offline() {
  return (
    <>
      <Header title="" />

      <Container className="mt-5 p-3 w-75">
        <div className="mt-5 border-start border-3 rounded border-danger bg-surface text-on-surface text-center shadow px-5 py-4">
          <div className="display-4 fw-light mb-2">
            <i className="bi bi-wifi-off ms-2 text-danger"></i>
          </div>

          <p className="fw-light fs-4">Recherche de réseau ...</p>
        </div>
      </Container>
    </>
  );
}
