import { useHistory } from "react-router-dom";
import useIdleTimer from "../../hooks/useIdleTimer";

export const IDLE_TIME_MS = 45 * 1000; // 40 sec

export function useRedirectHomeOnIdle() {
  const history = useHistory();
  useIdleTimer(IDLE_TIME_MS, () => {
    if (
      process.env.NODE_ENV === "production" &&
      history.location.pathname !== "/"
    )
      history.push("/");
  });
}
