import { useQuery, UseQueryOptions } from "react-query";
import { getConfig } from "../config";

interface GetReservationsFilter {
  active?: boolean;
  code?: string;
}

async function getReservations(
  filter?: GetReservationsFilter
): Promise<PaginatedResponse<Reservation>> {
  const { apiUrl, apiKey, lockerId } = getConfig();
  const url = new URL(`${apiUrl}/reservations/`);
  let params: any = {
    locker_id: lockerId,
  };
  if (filter?.active) params.active = filter.active.toString();
  if (filter?.code) params.code = filter.code;
  url.search = new URLSearchParams(params).toString();
  const headers = { "X-Locker-Private-Key": apiKey };
  const response = await fetch(url.toString(), { headers });
  if (!response.ok) {
    throw new Error("Network Error");
  }
  return response.json();
}

export default function useGetReservations(
  filter: GetReservationsFilter = {},
  options?: UseQueryOptions<PaginatedResponse<Reservation>>
) {
  return useQuery(
    ["reservations", filter],
    () => getReservations(filter),
    options as any
  );
}
