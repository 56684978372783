import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import { getConfig } from "../../config";

function NewReservation() {
  const { checkoutSlug } = getConfig();
  const { t } = useTranslation();

  return (
    <div className="text-center mt-4">
      <QRCode
        bgColor="#FFF8F8"
        fgColor="#004C3F"
        value={"https://expedilock.com/co/" + checkoutSlug}
      />

      <div className="mt-4 mb-3 lead">{t("home.newDetails")}</div>

      <h1 className="fw-light fs-2 text-primary">
        <u>
          expedilock.com<strong>/co/{checkoutSlug}</strong>
        </u>
      </h1>
    </div>
  );
}

export default NewReservation;
