// @flow

import { createContext, useState, useContext } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface ContextProps {
  warn: (title?: string, message?: string) => any;
}

const Ctx = createContext<ContextProps>({ warn: () => {} });

export function ToastProvider({ children }: any) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");

  const warn = (title: string = "", message: string = "") => {
    setTitle(title || t("common.warning"));
    setMessage(message || t("common.error"));
    setShow(true);
  };

  return (
    <Ctx.Provider value={{ warn }}>
      {children}
      <ToastContainer className="p-3" position="bottom-end">
        <Toast
          onClose={() => setShow(false)}
          show={show}
          delay={8000}
          autohide
          animation
        >
          <Toast.Header>
            <i className="bi bi-exclamation-triangle-fill me-2 text-danger"></i>
            <strong className="me-auto text-danger">{title}</strong>
          </Toast.Header>
          <Toast.Body>{message}</Toast.Body>
        </Toast>
      </ToastContainer>
    </Ctx.Provider>
  );
}

export const useToast = () => useContext(Ctx);
