import { useState } from "react";
import { ListGroup, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { DoorStuckHelpModal } from "./DoorStuckHelpModal";

interface LocationProps {
  reservation: Reservation;
}

export default function WithdrawSuccess() {
  const { t } = useTranslation();
  const [doorStuckShow, setDoorStuckShow] = useState(false);
  const history = useHistory<LocationProps>();

  return (
    <div className="position-absolute h-100 w-100 top-0 left-0">
      <Header title={t("withdrawSuccess.title")} />

      <Container className="mt-5 p-3 w-75">
        <div className="mt-5 border-start border-3 rounded border-primary bg-surface text-on-surface text-center shadow px-5 py-4">
          <div className="display-4 fw-light mb-2">
            {t("withdrawSuccess.thanks")}{" "}
            <i className="bi bi-patch-check ms-2 text-primary"></i>
          </div>

          <p className="fw-light fs-4">{t("withdrawSuccess.subtitle")}</p>
        </div>
      </Container>

      <Container className="fs-4 w-75" style={{ marginTop: 100 }}>
        <ListGroup>
          <ListGroup.Item
            action
            className="fw-light d-flex"
            onPointerUp={() => setDoorStuckShow(true)}
          >
            <div className="me-auto">Que faire si la case ne s'ouvre pas ?</div>
            <i className="bi-arrow-right"></i>
          </ListGroup.Item>
        </ListGroup>
      </Container>

      <Footer />

      <DoorStuckHelpModal
        show={doorStuckShow}
        onHide={() => setDoorStuckShow(false)}
        door={history.location.state?.reservation.door?.position}
      />
    </div>
  );
}
