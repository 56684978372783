import { useState } from "react";
import { Container, ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { ConfirmAddressHelpModal } from "./ConfirmAddressHelpModal";

interface LocationProps {
  code: string;
}

export default function InvalidCode() {
  const { t } = useTranslation();
  const history = useHistory<LocationProps>();
  const [confirmAddressShow, setConfirmAddressShow] = useState(false);

  return (
    <div className="position-absolute h-100 w-100 top-0 left-0">
      <Header title={t("invalidCode.title")} />

      <Container className="mt-5 p-3 w-75">
        <div className="mt-5 border-start border-3 rounded border-danger bg-surface text-on-surface text-center shadow px-5 py-4">
          <div className="display-4 fw-light mb-2">
            <i className="bi bi-exclamation-triangle-fill ms-2 text-danger"></i>
          </div>

          <p className="fw-light fs-4">{t("invalidCode.subtitle")}</p>
        </div>
      </Container>

      <Container className="fs-4 w-75" style={{ marginTop: 100 }}>
        <ListGroup>
          <ListGroup.Item
            action
            className="fw-light d-flex"
            onPointerUp={() => setConfirmAddressShow(true)}
          >
            <div className="me-auto">
              Comment savoir si je suis au bon casier ?
            </div>
            <i className="bi-arrow-right"></i>
          </ListGroup.Item>
        </ListGroup>
      </Container>

      <ConfirmAddressHelpModal
        show={confirmAddressShow}
        onHide={() => setConfirmAddressShow(false)}
        code={history.location.state?.code}
      />

      <Footer />
    </div>
  );
}
