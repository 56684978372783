import React from "react";
import ReactDOM from "react-dom";
import { App } from "./components/App";
import { connect } from "./ws";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    const waiting = registration.waiting;

    if (waiting) {
      waiting.addEventListener("statechange", (event) => {
        const target = event.target as ServiceWorker;
        if (target.state === "activated") {
          window.location.reload();
        }
      });
      waiting.postMessage({ type: "SKIP_WAITING" });
    }
  },
});

connect();
