import { useCallback, useState } from "react";
import { Container } from "react-bootstrap";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { Footer } from "../../components/Footer";
import { Numpad } from "../../components/Numpad";
import { Header } from "../../components/Header";
import useGetLocker from "../../hooks/useGetLocker";
import { useToast } from "../../components/Toast";

export default function RecipientPassword() {
  const { t } = useTranslation();
  const history = useHistory();
  const [code, setCode] = useState("");
  const [error, setError] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { warn } = useToast();
  const lockerQuery = useGetLocker({
    refetchOnMount: false,
    onError: () => warn(),
  });

  let onSubmit = useCallback(() => {
    setError(false);
    if (!lockerQuery.data) return;
    if (lockerQuery.data.config.deposit_code === parseInt(code)) {
      history.push("/select-recipient");
    } else {
      setDisabled(true);
      setTimeout(() => {
        setDisabled(false);
        setError(true);
      }, 1000);
    }
  }, [code, history, lockerQuery.data]);

  return (
    <div className="position-absolute h-100 w-100 top-0 left-0">
      <Header title={<i className="bi-lock"></i>} />

      <Container className="mt-4 pt-2">
        <Numpad
          disabled={disabled || lockerQuery.isLoading}
          loading={disabled}
          mask="*"
          className="shadow-sm py-4 rounded bg-surface bg-gradient"
          placeholder={
            error
              ? t("password.invalid")
              : disabled
              ? t("password.check")
              : t("password.code")
          }
          onChange={(code) => setCode(code)}
          onSubmit={onSubmit}
        />
      </Container>
      <Footer />
    </div>
  );
}
