export function initConfig(key: string, hint?: string) {
  const urlParams = new URLSearchParams(window.location.search);
  // first, look in the URL
  let value = urlParams.get(key);

  if (!value) {
    // second, look in local storage
    value = localStorage.getItem(key);
  }

  if (!value) {
    // third, prompt the user
    value = window.prompt(key, hint);
  }

  if (value) {
    localStorage.setItem(key, value);
  } else {
    console.error(`${key} is null`);
  }
}

export function getConfig() {
  const lockerId = localStorage.getItem("lockerId") || "";
  const apiKey = localStorage.getItem("apiKey") || "";
  const apiUrl = localStorage.getItem("apiUrl") || "";
  const wsUrl = localStorage.getItem("wsUrl") || "";
  const checkoutSlug = localStorage.getItem("checkoutSlug") || "";
  return { lockerId, apiKey, apiUrl, wsUrl, checkoutSlug };
}

/**
 * Quick Configuration
 */

if (window.location.search.includes("?test")) {
  localStorage.setItem("apiKey", "d2a05411-6a02-45d0-8103-9b56956811e1");
  localStorage.setItem("lockerId", "4YDEQRDK");
  localStorage.setItem("apiUrl", "https://api.expedibox.com");
  localStorage.setItem("wsUrl", "wss://35.203.109.132");
} else if (window.location.search.includes("?dev")) {
  localStorage.setItem("apiKey", "4b2971c3-a587-4709-8e56-b7a656d2b2c1");
  localStorage.setItem("lockerId", "7WJz7GqZ");
  localStorage.setItem("apiUrl", "http://api.expediboxdev.com:8000");
  localStorage.setItem("wsUrl", "ws://127.0.0.1:8000");
} else {
  initConfig("lockerId");
  initConfig("apiKey");
  initConfig("apiUrl", "http://api.expediboxdev.com:8000");
  initConfig("wsUrl", "ws://127.0.0.1:8000");
  initConfig("checkoutSlug");
}
