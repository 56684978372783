import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Container, Row, Col, Button } from "react-bootstrap";

interface Props {
  showBack?: boolean;
  showHome?: boolean;
}

const Footer: React.FC<Props> = ({
  showBack = true,
  showHome = false,
  children,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Container
      fluid
      className="position-absolute bottom-0 px-5 pb-3 border-bottom border-5 border-surface-variant"
    >
      <Row>
        <Col>
          {showBack && (
            <Button
              variant="link"
              className="fs-3 text-decoration-none"
              onPointerUp={() => history.goBack()}
            >
              <i className="bi-arrow-left-circle-fill me-2"></i>
              {t("footer.back")}
            </Button>
          )}
          {showHome && (
            <Button
              variant="link"
              className="fs-3 text-decoration-none"
              onPointerUp={() => history.push("/")}
            >
              <i className="bi-house-door-fill me-2"></i>
              {t("footer.home")}
            </Button>
          )}
        </Col>
        <Col className="text-center">{children}</Col>
        <Col className="text-end">
          <Button
            variant="link"
            className="fs-3 text-decoration-none"
            onPointerUp={() => history.push("/help")}
          >
            {t("footer.help")}
            <i className="bi-question-circle-fill ms-2"></i>
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
