import { QueryClient, QueryClientProvider } from "react-query";
import Router from "./Router";
import useIsOffline from "../../hooks/useIsOffline";
import { Offline } from "../../pages/Offline";
import "./i18n";

import "./app.scss";
import "./dark.scss";
import { MemoryRouter } from "react-router-dom";
import { ToastProvider } from "../Toast";

function App() {
  const isOffline = useIsOffline();
  if (isOffline) return <Offline />;

  return (
    <QueryClientProvider
      client={
        new QueryClient({
          defaultOptions: {
            queries: { refetchOnWindowFocus: false, refetchOnReconnect: false },
          },
        })
      }
    >
      <ToastProvider>
        <MemoryRouter>
          <Router />
        </MemoryRouter>
      </ToastProvider>
    </QueryClientProvider>
  );
}

export default App;
