import { useQuery, UseQueryOptions } from "react-query";
import { getConfig } from "../config";

async function getLocker(): Promise<Locker> {
  const { apiUrl, apiKey, lockerId } = getConfig();
  const url = `${apiUrl}/lockers/${lockerId}/`;
  const headers = { "X-Locker-Private-Key": apiKey };
  const response = await fetch(url, { headers });
  if (!response.ok) {
    throw new Error("Network Error");
  }
  return response.json();
}

export default function useGetLocker(options?: UseQueryOptions) {
  const { lockerId } = getConfig();
  return useQuery(["locker", lockerId], getLocker, options as any);
}
