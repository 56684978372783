import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import useOpenDoor from "../../hooks/useOpenDoor";

interface Props {
  show: boolean;
  onHide: () => any;
  door?: string;
}

const WARN_CLICKS = 10;
const MAX_CLICKS = 20;

export function DoorStuckHelpModal({ show, onHide, door }: Props) {
  const openDoor = useOpenDoor();
  const [clicks, setClicks] = useState(0);

  return (
    <Modal show={show} onHide={onHide} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <i className="bi-question-circle me-2"></i>Aide
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>
          <strong>Que faire si la case ne s'ouvre pas ?</strong>
        </p>

        <p className={clicks >= MAX_CLICKS ? "text-muted" : ""}>
          Vous pouvez essayer de réouvrir la case en appuyant quelques fois sur
          ce bouton:
        </p>

        <div className="d-flex justify-content-center my-3">
          <Button
            size="lg"
            disabled={clicks >= MAX_CLICKS}
            onPointerUp={() => {
              if (door) openDoor.mutate(door);
              setClicks(clicks + 1);
            }}
          >
            Réouvrir la case
          </Button>
        </div>
        {clicks >= WARN_CLICKS && (
          <p className="pt-2">
            Si la case reste toujours fermée, vous pouvez nous contactez en
            utilisant la page d'aide du casier.
          </p>
        )}
      </Modal.Body>
    </Modal>
  );
}
